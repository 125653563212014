import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Shop.css";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import Swal from "sweetalert2";
import emptyProfileImage from "../../../src/Pages/gifs/emptyprofile.svg";
import NavBar from "../../Components/GeneralComponents/NavBar";

export default function Shop() {
  const [products, setProducts] = useState(null);
  const [vendorName, setVendorName] = useState();
  const [shopLink, setShopLink] = useState();
  const [vendorProfile, setVendorProfile] = useState();
  const [loading, setLoading] = useState(true)
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const devBaseUrl =  process.env.REACT_APP_DEV_BASE_URL
  const [cartLength, setCartLength] = useState(0);

  let { vendorShopTag } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getProducts();
    //localStorage.setItem('cart', null)
    localStorage.setItem("shopUrl", null)
    localStorage.setItem("shopUrl", devBaseUrl +"/"+ vendorShopTag )
    setCartLength(JSON.parse(localStorage.getItem('cart'))?.length ?? 0)

    async function getDeliveryFee(){
      await axios.get(`${baseUrl}/vendor/api/checkout-delivery-fee`).then(function(response){
        if(response.status === 200){
          localStorage.setItem("deliveryFee", response.data.data[1].fee)
        }
      })
    }
  
    getDeliveryFee()
  }, []);

  const showAlert = (errorMessage) => {
    Swal.fire({
      title: "Something went wrong",
      text: errorMessage,
      icon: "error",
    });
  };

  const getProducts = async () => {
    try {
      console.log(vendorShopTag);

      const shopPayLoad = {
        shopTag: vendorShopTag ?? "PoyntShop",
      }

      console.log(shopPayLoad);

      const response = await axios.get(
        `${baseUrl}/vendor/api/sys/vendor-products?page=1&limit=100&shopTag=${vendorShopTag ?? 'PoyntShop'}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseData = response?.data;

      const productData = responseData.data || [];

      if (productData) {
        console.log(responseData);
        setProducts(productData);
        

        if (productData.length > 0) {
          const vendor = productData[0].vendor;

          setVendorName(vendor.shopTag);
          setShopLink(vendor.shareLink);
          setVendorProfile(vendor.shopLogoUrl);
        }else{
          showAlert(`Sorry ${vendorShopTag} is empty`); 
          setTimeout(function(){
            window.location = window.location.href.replace(vendorShopTag, 'PoyntShop');
          }, 3000);
        }
      }else{
        showAlert(`Sorry ${vendorShopTag} is empty`); 
        setTimeout(function(){
          window.location = window.location.href.replace(vendorShopTag, 'PoyntShop');
        }, 3000);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        showAlert("Cannot find shop"); 
        setTimeout(function(){
          window.location = window.location.href.replace(vendorShopTag, 'PoyntShop');
        }, 3000);
      } else {
        showAlert("Cannot find shop"); 
        setTimeout(function(){
          window.location = window.location.href.replace(vendorShopTag, 'PoyntShop');
        }, 3000);
      }
      setLoading(false);
    }
  };

  function redirectToShop(shopLink) {
    window.location.href = shopLink;
  }

  
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
       
        <div className="shop-main-container">
      <NavBar cartLength={cartLength} />
          <div className="vendor-profile-container">
              <div className="vendor-profile">
                <div className="profile-image-wrapper">
                  <img
                    className="profile-image"
                    src={vendorProfile !== null ? vendorProfile : "https://image.itspoynt.shop/images/1c18ebbb65c948209e08d3d918a1eff9.png"}
                    alt="Vendor Profile" />
                </div>
                <div className="shop-name">{vendorName}</div>
                <button
                  className="visit-shop-cta"
                  onClick={() => redirectToShop(shopLink)}
                >
                  Visit shop on Poynt
                  <img
                    className="link-arrow"
                    src={"/favicon/link-arrow.svg"}
                    alt="Link Arrow" />
                </button>
              </div>
            </div>
            </div>
      )}

      {/* This section is for the products in the shop */}
      <ul className="Product-grid-container">
        {products &&
          products.map((product) => (
            <li key={product.id} className="col">
              <Link to={`/products/${product.externalId}`}>
                {product.quantity === 0 && (
                  <div className="sold-overlay">SOLD</div>
                )}
                <div className="detail-overlay">
                <div className="detail-size">{product.size?.split(',').length > 1 ? "Multiple" :product.size}</div>
                  {product.isDiscounted == true ? (
                    <div>
                      <div className="detail-discounted">
                        ₵{product.discountPrice}
                      </div>
                      <div className="detail-amount-discount">₵{product.unitPrice}</div>
                    </div>
                  ): (<div className="detail-amount">₵{product.unitPrice}</div>)} 
                </div>
                <img className="col-image" src={product.photos[0]} alt="p1" />
              </Link>
            </li>
          ))}

      </ul>
    </div>
  );
}
