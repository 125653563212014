import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./PaymentStatus.css";

export default function PaymentStatus() {
  const navigate = useNavigate();
  const { paymentStatus } = useParams();
  const storeDownload = "https://bit.ly/DownloadPoynt";
  const whatsappLink = "https://bit.ly/PoyntCommunity"; 
  const storedValue = localStorage.getItem("vendorId");

  function redirectToStore(storeDownload) {
    window.location.href = storeDownload;
  }

  function redirectToCommunity(whatsappLink) {
    window.location.href = whatsappLink;
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log(storedValue);
      storedValue ? navigate(`/${storedValue}`) : navigate(`/`);
    }, 30000);
  }, []);

  return (
    <div>
      <div className="payment-status-container">
        {/* This section is shows the Success Status*/}
        {paymentStatus === "success" && (
          <div className="success-status-container">
            <img
              className="success-gif"
              src={"/gifs/success.gif"}
              alt="Success Animation"
            />
            <div className="status-title">Payment Completed</div>
            <div className="status-subtitle">
              Download the Poynt App to pay for products easily and discover
              more amazing products.
            </div>
            <div className="redirection-card">
              <div className="store-ctas" onClick={() => redirectToStore(storeDownload)}>
                <img
                  className="store-image"
                  src={"/images/play-store.png"}
                  alt="PLaystore"
                />
                <img
                  className="store-image"
                  src={"/images/app-store.png"}
                  alt="Applestore"
                />
              </div>
              <button className="community-cta" onClick={() => redirectToCommunity(whatsappLink)}>
                <img
                  className="community-waving-icon"
                  src={"/favicon/waving-hand.svg"}
                  alt="Waving Emojie"
                />
                Join our Whatsapp Community
              </button>
            </div>
          </div>
        )}

        {/* This section is shows the Failed Status*/}
        {paymentStatus === "failed" && (
          <div className="failed-status-container">
            <img
              className="failed-gif"
              src={"/gifs/failed.gif"}
              alt="Success Animation"
            />
            <div className="failed-status-title">Payment Failed</div>
            <div className="status-subtitle">
              Payment wasn’t successful. Please try again.
            </div>
            <div className="redirection-card">
              <div className="store-ctas" onClick={() => redirectToStore(storeDownload)}>
                <img
                  className="store-image"
                  src={"/images/play-store.png"}
                  alt="PLaystore"
                />
                <img
                  className="store-image"
                  src={"/images/app-store.png"}
                  alt="Applestore"
                />
              </div>
              <button className="community-cta" onClick={() => redirectToCommunity(whatsappLink)}>
                <img
                  className="community-waving-icon"
                  src={"/favicon/waving-hand.svg"}
                  alt="Waving Emojie"
                />
                Join our Whatsapp Community
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
