import './App.css';
import Shop from './Pages/Shop/Shop';
import Products from './Pages/ProductPage/ProductPage';
import Status from './Pages/PaymentStatus/PaymentStatus';
import Checkout from './Pages/Checkout/Checkout';
// import { BrowserRouter as Route, Router, Routes} from 'react-router-dom';
import { BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Cart from './Pages/Cart/Cart';

function App() {
  return (
    <Router>
      <Routes>
        <Route  path="/" element={<Shop/>}></Route>
        <Route  path="/:vendorShopTag" element={<Shop/>}></Route>
        <Route path="/products/:productExternalId" element={<Products/>}></Route>
        <Route path="/status/:paymentStatus" element={<Status/>}></Route>
        <Route path="/product" element={<Products/>}></Route>
        <Route path="/cart" element={<Cart/>}></Route>
        <Route path="/status" element={<Status/>}></Route>
        <Route path="/checkout" element={<Checkout/>}></Route>
        <Route  path="*" element={<Shop/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
