import React from "react";
import "./Loader.css";

const Loader = () => {
    return (
      <div className="payment-processing-modal">
      <div className="payment-processing">
        <img
          className="processing-animation"
          src={"/gifs/loader.gif"}
          alt="Processing Loader"
        />
      </div>
    </div>
);
  };
  
  export default Loader;