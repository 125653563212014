import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./ProductPage.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Swal from "sweetalert2";
import Loader from "../../Components/Loader/Loader";
import NavBar from "../../Components/GeneralComponents/NavBar";
import CartLoader from "../../Components/CartLoader/CartLoader";

export default function Products() {
  const navigate = useNavigate();
  const { productExternalId } = useParams();
  const [product, setProduct] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [loading, setLoading] = useState(true);
  const [cartLoading, setCartLoading] = useState(false);

  const storeDownload = "https://bit.ly/DownloadPoynt";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accountName, setAccountName] = useState();
  const [size, setSize] = useState();
  const [quantity, setQuantity] = useState(1);
  const [amountPaid, setAmountPaid] = useState();
  const [addToCartModalToggle, setAddToCartModalToggle] = useState(false);
  const [sendToCartModalToggle, setSendAddToCartModalToggle] = useState(false);

  const [cartLength, setCartLength] = useState(0);
  const [quantityError, setQuantityError] = useState(false);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) ?? []);
  const [shopLink, setShopLink] = useState(localStorage.getItem("shopUrl"));


  const [values, setValues] = useState({
    accountNumber: "",
  });
  const inputRef = useRef();
  

  useEffect(() => {
    getProductDetails();
    setCartLength(JSON.parse(localStorage.getItem('cart'))?.length ?? 0)
   
  }, []);

  
  const showAlert = (errorMessage) => {
    Swal.fire({
      title: "Something went wrong",
      text: errorMessage,
      icon: "error",
    });
  };


  function redirectToStore(storeDownload) {
    window.location.href = storeDownload;
  }
  const handleSizeChange = (event) => {
    setSize(event.target.value);
    console.log(event.target.value);
  };

  const handleQuantity = (event) => {
    setQuantity(event.target.value);
    console.log(quantity);
  };

  function handleKeyPress(event) {
    if (
      parseInt(document.getElementById("quantity").value) >
      parseInt(product.quantity)
    ) {
      setQuantityError(true);
      setQuantity(parseInt(product.quantity))
      setAmountPaid(document.getElementById("quantity").value*product.quantity);
      console.log(document.getElementById("quantity").value*product.quantity);
    } else {
      setQuantityError(false);
    }
  }



  const getProductDetails = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/vendor/api/sys/product/${productExternalId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseData = response?.data;

      const productData = responseData.data || [];

      if (productData) {
        setProduct(productData);
        console.log(responseData);
        setSize(productData.size);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        showAlert(error.response.data.message);
      } else {
        showAlert("An error occurred");
      }
      setLoading(false);
    }
  };

 

  const addToCart = () => {
    console.log(product)
    setSendAddToCartModalToggle(true)

    setTimeout(function(){
      setSendAddToCartModalToggle(false)
    }, 60000)

    let cartPayload = {
      vendorId: product.vendor.id,
      productId: product.id,
      quantity: quantity ?? 1,
      actualProductQuantity: product.quantity,
      color: product.color,
      size: size,
      productName: product.name,
      cartAmount: product.isDiscounted ? product.discountPrice : product.unitPrice,
      initialAmount:product.isDiscounted ? product.discountPrice : product.unitPrice,
      cartImage: product.photos[0],
      incremental: 0,
      userType: "guest",
      recipient: {
        name: accountName,
        phone: "+233" + values.accountNumber, //what happens if the user adds 233
      },
    };
    var data = localStorage.getItem('cart'); 

    if (data == undefined || data == null || data == "")
    {
              let cartData = [];
              cartData.push(cartPayload)
              localStorage.setItem('cart', JSON.stringify(cartData));
    }else{
            let cartData = JSON.parse(localStorage.getItem('cart'));
            if(cartData){
              let checkIfExists = cartData.find((cartItem) => {
                return cartItem.productId === cartPayload.productId
              })
              if (checkIfExists) {
                
                return;
              }else{
                cartData.push(cartPayload)
                localStorage.setItem('cart', JSON.stringify(cartData));
              }
            }else{
              
              let cartData = [];
              cartData.push(cartPayload)
              localStorage.setItem('cart', JSON.stringify(cartData));
            }
            console.log(JSON.parse(localStorage.getItem('cart')))
            setCartLength(JSON.parse(localStorage.getItem('cart'))?.length)

    }

    setCartLength(JSON.parse(localStorage.getItem('cart'))?.length)

          
  }

  const sendCartToAPI = () => {
    
  }
     
 


  return (
    <div>
      <NavBar cartLength={cartLength} />
       {/* This section is a modal loader for processing payment*/}
       {addToCartModalToggle && (
          <div className="add-to-cart-modal">
            <div className="add-to-cart">
                <div className="cart-pref">
                    <form action="/cart">
                        <ul className='checkout-input'>
                            
                             
                              <li style={{display:"none"}} className='dropdown-parent'>
                              <div className="input-title">Select Size</div>
                                <img className="arrow-down" src={"/favicon/arrow-down.svg"} alt="arrow-down"/>
                                <select
                                  name="size" 
                                  id="size" 
                                  className="size-select"
                                  onChange={(e) => {handleSizeChange(e)}}
                                  value={size}
                                  required
                                  >
                                  <option value="">Size</option>
                                  {product?.size?.split(',').map((size, index) => (
                                    <option key={index} value={size}>
                                      {size}
                                    </option>
                                  ))}
                                </select>
                            </li>


                            <li>
                              <div className="input-title">Enter Quantity</div>
                              <input
                                id="quantity"
                                className="momo-input"
                                type="text"
                                placeholder="Quantity"
                                name="momo"
                                onChange={handleQuantity}
                                onKeyUp={(e) => handleKeyPress(e)}
                                value={quantity}
                                required
                              />
                            </li>
                  {quantityError && (
                    <div className="quantity-error">Quantity exceeds available stock.</div>
                  )}
                        </ul>

                        {/* This the submit button */}
                        <input  onClick={() =>setAddToCartModalToggle(!addToCartModalToggle)} className="buy-cta" type="submit" value="Sure"/>
                    </form> 
                </div>
            </div>
          </div>
        )
      }

      {
        sendToCartModalToggle && (
          <div className="add-to-cart-modal">
            <div className="add-to-cart">
                <div className="cart-pref">
                    <form>
                           
                        <ul className='checkout-input'>
                            
                            <div className="input-title"></div>

                              <li style={{display:"flex", justifyContent:"center", alignContent:"center"}} className='dropdown-parent'>
                              <p style={{color:"#0264fb", marginTop:"10px"}}><b>{product.name}</b>&nbsp;
                             
                               added!</p>
                              </li>
                   
                        </ul>

                        <Link to={'/cart'}> 
                          <input   className="buy-cta" type="submit" value="Checkout"/> 
                        </Link>
                        
                        <Link to={shopLink}> 
                          <input   className="buy-cta2" type="submit" value="Continue shopping"/>
                        </Link>
                    </form> 
                </div>
            </div>
          </div>
        )
      }
      {
        cartLoading ? (
          <CartLoader />
        ) :(
          <div></div>
        )}
      
     
      {loading ? (
        <Loader />
      ) : (
        <div className="product-detail-container">
          {/* This section is the image container */}
          {product && (
            <div className="product-detail-image">
              <div className="product-detail-overlay">
                <div className="product-detail-size">{product.name}</div>
                {product.isDiscounted == true ? (
                  <div className="product-detail-amount">
                    ₵{product.discountPrice}
                  </div>
                ) : (
                  <div className="product-detail-amount">
                    ₵{product.unitPrice}
                  </div>
                )}
              </div>
              <img
                className="p-detail-image"
                src={product.photos[0]}
                alt="p2"
              />
            </div>
          )}
          {/* This section is the free delivery and buyer protection container */}
          {/* <div className="protection-free-delivery">
            <div className="buyer-protection-message">
              <img
                className="shield-icon"
                src={"/favicon/shield-check.svg"}
                alt="shield-check"
              />
              <div className="protection-message">Buyer Protection Policy</div>
            </div>
            <div className="free-delivery-message">Free Delivery</div>
          </div> */}

          {/* This section is the input field container */}
          {product.quantity > 0 && (
            <div className="product-checkout">
              <form action="/Status">
                {/* <ul className="checkout-input">
                  <li>
                    <div className="input-title">Enter mobile money number</div>
                    <input
                      id="accountNumber"
                      className="momo-input"
                      type="number"
                      placeholder="Momo number"
                      name="momo"
                      onChange={handleChange}
                      value={values.accountNumber}
                      required
                    />
                  </li>
                  {nameResolved ? (
                    "Loading..."
                  ) : (
                    <div className="resolved-name">{accountName}</div>
                  )}
                  <li>
                    <div className="input-title">Select Network</div>
                    <select
                      id="provider"
                      className="momo-input"
                      name="provider"
                      onChange={(e) => {
                        const selectedNetwork = e.target.value;
                        const abbreviationMap = {
                          MTN: "MTN",
                          AirtelTigo: "ATL",
                          Vodafone: "VOD",
                        };
                        console.log(
                          "Selected network here",
                          abbreviationMap[selectedNetwork]
                        );
                        console.log(typeof selectedNetwork);
                        setSelectedNetwork(abbreviationMap[selectedNetwork]);
                      }}
                      required
                    >
                      <option value="">Network</option>
                      <option value="MTN">MTN</option>
                      <option value="AirtelTigo">AirtelTigo</option>
                      <option value="Vodafone">Vodafone</option>
                    </select>
                  </li>
                 
                  <li>
                    <div className="input-title">Select Delivery location</div>
                    {isLoaded && (
                      <StandaloneSearchBox
                        onLoad={(ref) => {
                          inputRef.current = ref;
                        }}
                        onPlacesChanged={hanlePlaceChanged}
                      >
                        <input
                          className="location-input"
                          type="text"
                          placeholder="Location"
                          name="Location"
                          required
                        />
                      </StandaloneSearchBox>
                    )}
                  </li>
                </ul> */}

                {/* This the submit button */}
        
                {/* <button className="add-size-quantity" type="button" onClick={() =>setAddToCartModalToggle(!addToCartModalToggle)}>
                  Add quantity 
                </button> */}
                {product.isDiscounted == true ? (
                  <button className="buy-cta" type="button" onClick={addToCart}>
                      Add to Cart (₵{product.discountPrice})
                  </button>
                ) : (
                  <button className="buy-cta" type="button" onClick={addToCart}>
                  Add to Cart (₵{product.unitPrice})
                  </button>
                )}
              </form>
            </div>
          )}

          {/* This section is the redirection to download app*/}
          <div className="download-app-container">
            <div className="download-app">
              <img
                className="waving-icon"
                src={"/favicon/waving-hand.svg"}
                alt="Waving Emojie"
              />
              <div className="redirect-details">
                <div>
                  Hello, <br /> Want to see more details on this product?
                </div>
                <div className="download-app-cta-container">
                  <button
                    className="download-app-cta"
                    onClick={() => redirectToStore(storeDownload)}
                  >
                    <div>Download the App</div>
                    <img
                      className="cta-arrow"
                      src={"/favicon/link-arrow-black.svg"}
                      alt="Link Arrow"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
