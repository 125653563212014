import {React,useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

export default function 
(props) {

    const[cart, setCart] = useState([]);
    const[homeUrl, setHomeUrl] = useState(localStorage.getItem("shopUrl"));


    useEffect(() => {
      setCart(localStorage.getItem('cart'));
      
    }, [])
    
  return (
    <div>
         <div className="header">
            <div className="header-container">
                <Link to={homeUrl}>
                    <div className="logo">
                        <img className="poynt-logo" src={"/favicon/poynt-logo.svg"} alt="Poynt logo"/>
                    </div>
                </Link>
                
                <Link to={'/cart'}>
                    <div className="cart">
                        <div className="cart-count">{props.cartLength}</div>
                        <img className="cart-icon" src={"/favicon/cart-icon.svg"} alt="cart icon"/>
                    </div>
                </Link>
            </div>
        </div>
    </div>
  )
}
