import React from "react";
import "./CartLoader.css";

const CartLoader = () => {
    return (
      <div className="payment-processing-modal">
      <div className="payment-processing">
        <img
          style={{height:"25px", width:"25px", marginTop:"10px"}}
          className="processing-animation"
          src={"/images/cart.png"}
          alt="Processing Loader"
        />
        <p style={{fontSize:"10px", color:"#0264fb", marginTop:"10px"}}>Added to Cart</p>
      </div>
    </div>
);
  };
  
  export default CartLoader;