import './Checkout.css';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

import Swal from "sweetalert2";
import Loader from "../../Components/Loader/Loader";

export default function Checkout() {
    const navigate = useNavigate();

    const [nameResolved, setNameResolved] = useState(false);
    const [accountName, setAccountName] = useState();
    const [selectedNetwork, setSelectedNetwork] = useState();
    const [checkoutProcessing, setCheckoutProcessing] = useState(false);
    const [checkOutCart, setCheckOutCart] = useState([]);
    const [checkIfMomoIsRight, setCheckIfMomoIsRight] = useState(false);


    const [product, setProduct] = useState();
    const [size, setSize] = useState();
    const [quantity, setQuantity] = useState(1);
    const [checkBalance, setCheckBalance] = useState(false);
    const [total, setTotal] = useState(0)
    const [price, setPrice] = useState(0)
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [recipientAlternativeContact, setRecipientAlternativeContact] = useState("");


    const [values, setValues] = useState({
        accountNumber: "",
        recipientAlternativeContact:""
      });
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const inputRef = useRef();
    var count;

    const [address, setAddress] = useState({
        name: "",
        locality: "",
        neighbourhood: "",
        sublevel1: "",
        state: "",
        country: "",
        latitude: "",
        longitude: "",
      });
    useEffect(() => {
        
        var data = localStorage.getItem('cart'); 

        if (data == undefined || data == null || data == "")
        {
            navigate(localStorage.getItem('shopUrl'))
        }else{
            let sum = 0;
            let quantity = 0;
            console.log(JSON.parse(localStorage.getItem('cart')))
            setCheckOutCart(JSON.parse(localStorage.getItem('cart')))    

            JSON.parse(localStorage.getItem('cart')).forEach((el) =>  {sum += el.cartAmount})
            JSON.parse(localStorage.getItem('cart')).forEach((el) =>  {quantity += el.quantity})
            setPrice(sum)
            setQuantity(quantity)
            setDeliveryFee(parseInt(localStorage.getItem("deliveryFee")) ?? 31)
            // setTotal(sum+parseInt(localStorage.getItem("deliveryFee")) ?? 31)
            setTotal(sum)



        }


        if (selectedNetwork && values.accountNumber) {
          resolveName();
        }
      }, [selectedNetwork, values.accountNumber]);
    
      const showAlert = (errorMessage) => {
        Swal.fire({
          title: "Something went wrong",
          text: errorMessage,
          icon: "error",
        });
      };






  

  const resolveName = async () => {
    if (values.accountNumber.length > 9) {
      try {
        const nameResolution = await axios.get(
          `${baseUrl}/api-gateway/api/accounts/resolve?accountNumber=${values.accountNumber}&bankCode=${selectedNetwork}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (nameResolution.data.data.name) {
          setAccountName(nameResolution.data.data.name);
          setCheckIfMomoIsRight(true)
          setNameResolved(false);
        }else{
          setAccountName(nameResolution?.data?.data?.name ?? "Momo number error, check again please");
          setCheckIfMomoIsRight(false)
        }
        console.log("Name Resolution Response:", nameResolution.data.data.name);
      } catch (error) {
        setAccountName("failed to resolve name");
        setNameResolved(false);
        setCheckIfMomoIsRight(false)
      }
    }
  };

  const goBack= () => {
    navigate(-1)
  }

  const calcCart = async() => {
    let totalCart = []
    
    setCheckoutProcessing(true);

    for(let x = 0; x < checkOutCart.length; x++){
        
            console.log(accountName);
            const cartPayload = checkOutCart[x]
            console.log(cartPayload);
    
            const cartResponse = await axios.post(
              `${baseUrl}/vendor/api/sys/add-to-cart`,
              cartPayload,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            );
            console.log(cartResponse)
            totalCart.push(cartResponse?.data.data.externalId)
        
    }
    return totalCart
  }

  const checkout = async (e) => {
    e.preventDefault()
    try {
        let totalCart = await calcCart()
      setCheckoutProcessing(true);
      console.log("submitted:::", totalCart)

        if (totalCart != null  || totalCart != undefined) {
          const checkoutPayload = {
            cartExternalIds: totalCart,
            description: "Guest checkout",
            deliveryAmount: 0,
            destination: address.name,
            recipientContact: values.accountNumber,
            recipientAlternativeContact:values.recipientAlternativeContact,
            recipientSortCode: selectedNetwork,
            deliveryOption: "SAME_DAY",
            recipient: {
              name: accountName,
              phone:  values.accountNumber, //what happens if the user adds 233
            },
            destination: {
              name: address.name,
              city:
                address.locality +
                "" +
                address.neighbourhood +
                "" +
                address.sublevel1,
              state: address.state,
              country: address.country,
              countryCode: "GH",
              latitude: address.latitude,
              longitude: address.longitude,
            },
          };

          console.log("checkoutPayload:::",checkoutPayload);

          const orderResponse = await axios.post(
            `${baseUrl}/vendor/api/sys/checkout`,
            checkoutPayload,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                checkoutPayload,
              }),
            }
          );
          console.log(orderResponse);
          if (orderResponse) {
            console.log(
              "Local storage",
              orderResponse.data.data.vendor.shopTag
            );
            localStorage.setItem(
              "vendorId",
              orderResponse.data.data.vendor.shopTag
            );
          }

          console.log(orderResponse.data.data.externalId);
          const orderStatusPayload = {
            orderExternalId: orderResponse.data.data.externalId,
          };

          console.log(orderStatusPayload);
          let success = false;
          let whileCount = 0;
          while (!success) {
            try {
              const statusResponse = await axios.post(
                `${baseUrl}/vendor/api/check-status`,
                orderStatusPayload,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  body: JSON.stringify({
                    orderStatusPayload,
                  }),
                }
              );
              console.log(statusResponse.data.data);
              if (statusResponse.data.data) {
                whileCount++;
              }
              if (
                statusResponse.data.data === "success" ||
                statusResponse.data.data === "failed"
              ) {
                success = true;
                setCheckoutProcessing(false);
                navigate(`/Status/${statusResponse.data.data}`);
              }
              console.log("whilecount::" + whileCount);
              if (whileCount === 5 && statusResponse.data.data === "pending") {
                console.log("whilecountifsuccessful" + count);
                setCheckBalance(true);
                localStorage.setItem('cart', null);
                const timeoutId = setTimeout(() => {
                  navigate(`/${localStorage.getItem("vendorId")}`);
                }, 30000);
                success = true;
                continue;
              }

              await new Promise((resolve) => setTimeout(resolve, 60000));
            } catch (error) {
              console.error("Error:", error);
              if (error.response) {
                console.error("Error Response Data:", error.response.data);
                showAlert(error.response.data.message);
              } else {
                showAlert("An error occurred");
              }
              setCheckoutProcessing(false);
            }
          }
        }
      
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        showAlert(error.response.data.message);
      } else {
        showAlert("An error occurred");
      }
      setCheckoutProcessing(false);
    }
  };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleApiKey,
        libraries: ["places"],
      });

        
    //   getting the selected network
    const handleChange = (event) => {
        setValues({ ...values, [event.target.id]: event.target.value });
        console.log(values);
    };

      const hanlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
          const addressComponents = place.address_components || [];
    
          const updatedAddress = { ...address };
    
          for (const component of addressComponents) {
            for (const type of component.types) {
              if (type === "locality") {
                updatedAddress.locality = component.long_name ?? "";
              } else if (type === "administrative_area_level_1") {
                updatedAddress.state = component.long_name ?? "";
              } else if (type === "country") {
                updatedAddress.country = component.long_name ?? "";
              } else if (type === "neighbourhood") {
                updatedAddress.neighbourhood = component.long_name ?? "";
              } else if (type === "sublocality_level_1") {
                updatedAddress.sublevel1 = component.long_name ?? "";
              }
            }
          }
    
          updatedAddress.latitude = place.geometry.location.lat();
          updatedAddress.longitude = place.geometry.location.lng();
          updatedAddress.name = place.formatted_address;
          setAddress(updatedAddress);
        }
      };

      

      return (
          
        <div>   
              {checkoutProcessing && (
                    <div className="payment-processing-modal">
                    <div className="payment-processing">
                        <img
                        className="processing-animation"
                        src={"/gifs/loader.gif"}
                        alt="Processing Loader"
                        />
                        <div className="animation-title">Processing Order</div>
                    </div>
                    </div>
                )} 

             <div className="checkout-main-container">
  
      {/* This section is the modal that shows when the loading keeps time */}
      {checkBalance && (
        <div className="payment-processing-modal">
          <div className="payment-processing">
            <img
              className="processing-animation"
              src={"/gifs/failed.gif"}
              alt="Processing Loader"
            />
            <div className="animation-title">
              Error Check your balance and try again.
            </div>
          </div>
        </div>
      )}
            <div className="header">
                <div className="header-container">
                    <div className="cart-nav-header">
                        <img onClick={goBack} className="back-icon" src={"/favicon/arrow-left.svg"} alt="Poynt logo"/>
                        Checkout
                    </div>
                </div>
            </div>

            {/* This section is for the payment summary */}
            <div className="order-summary">
                <ul className="order-summary-wrapper">
                    <li className="order-sage-style">
                        <div>Quantity</div>
                        <div>{quantity}</div>
                    </li>
                    <li className="order-sage-style">
                        <div>Price</div>
                        <div>₵{price.toFixed(2)}</div>
                    </li>
                    <li className="order-sage-style">
                        <div>Delivery Cost</div>
                        {/* deliveryFee.toFixed(2) */}
                        <div>₵{(deliveryFee && deliveryFee != '') ? 0: 0}</div>
                    </li>
                    <li className="order-total order-sage-style">
                        <div>Total</div>
                        <div>₵{total.toFixed(2)}</div>
                    </li>
                </ul>
            </div>
 {/* This section is a modal loader for processing payment*/}
            <form onSubmit={checkout}>

                {/* This section is for delivery details */}
                <div className="location-details">
                    <div className="location-details-title">Delivery details</div>
                        <ul className='checkout-page-input'>
                                <li>
                                    {/* <div className="checkout-input-title">Recipient number</div> */}
                                    <input 
                                    id="recipientAlternativeContact"
                                    className="momo-input" 
                                    type='number' 
                                    placeholder='Enter recipient number'
                                    name="recipientAlternativeContact"
                                    onChange={handleChange}
                                    defaultValue={values.recipientAlternativeContact}
                                    required
                                    />
                                </li>
                                { checkIfMomoIsRight && values.recipientAlternativeContact === ""  ? (
                                  <div className="resolved-name" style={{color:"red"}}>{"Please enter recipient number"}</div>
                                ) : (
                                  ""
                                )}
                                <li>
                                    <div className="input-title">Select Delivery location</div>
                                    {isLoaded && (
                                    <StandaloneSearchBox
                                        onLoad={(ref) => {
                                        inputRef.current = ref;
                                        }}
                                        onPlacesChanged={hanlePlaceChanged}
                                    >
                                        <input
                                        className="location-input"
                                        type="text"
                                        placeholder="Location"
                                        name="Location"
                                        required
                                        />
                                    </StandaloneSearchBox>
                                    )}
                                </li>
                                { checkIfMomoIsRight && address.latitude ==="" && address.longitude === ""  ? (
                                  <div className="resolved-name" style={{color:"red"}}>{"Kindly type and select your exact location from the dropdown list above ☝️"}</div>
                                ) : (
                                  ""
                                )}
                            </ul>
                    
                </div>
                {/* This section is for the payment method*/}
                <div className="location-details">
                    <div className="location-details-title">Payment method</div>
                        <ul className='checkout-page-input'>
                                <li>
                                    <div className="input-title">Select Network</div>
                                    <select
                                    id="provider"
                                    className="momo-input"
                                    name="provider"
                                    onChange={(e) => {
                                        const selectedNetwork = e.target.value;
                                        const abbreviationMap = {
                                        MTN: "MTN",
                                        AirtelTigo: "ATL",
                                        Vodafone: "VOD",
                                        };
                                        console.log(
                                        "Selected network here",
                                        abbreviationMap[selectedNetwork]
                                        );
                                        console.log(typeof selectedNetwork);
                                        setSelectedNetwork(abbreviationMap[selectedNetwork]);
                                    }}
                                    required
                                    >
                                    <option value="">Network</option>
                                    <option value="MTN">MTN</option>
                                    <option value="AirtelTigo">AirtelTigo</option>
                                    <option value="Vodafone">Vodafone</option>
                                    </select>
                                </li>

                                <li>
                                    <div className="input-title">Enter mobile money number</div>
                                    <input
                                    id="accountNumber"
                                    className="momo-input"
                                    type="number"
                                    placeholder="Momo number"
                                    name="momo"
                                    onChange={handleChange}
                                    value={values.accountNumber}
                                    required
                                    />
                            </li>
                                {nameResolved ? (
                                    "Loading..."
                                ) : (
                                    <div className="resolved-name">{accountName}</div>
                                )}
                            </ul>
                         
                    <div>
                
                </div>
                </div>
                {checkIfMomoIsRight && address.latitude && address.longitude ? ( <input className="checkout-cta" type="submit" value="Pay"/>)
                :
                (<input className="checkout-cta" style={{opacity: 0.5}} disabled type="submit" value="Pay"/>
                )
                } 
            </form>
         </div>
        </div>
      )

}