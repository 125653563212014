import {React, useEffect, useState} from 'react'
import './Cart.css';
import { Link, useNavigation } from "react-router-dom";
import NavBar from '../../Components/GeneralComponents/NavBar';
import Loader from '../../Components/Loader/Loader';


function Cart() {
    const [cart, setCart] = useState([])
    const [cartLength, setCartLength] = useState(0);
    const [total, setTotal] = useState(0)
    const [price, setPrice] = useState(0)
    const [loading, setLoading] = useState(true);




    useEffect(() => {
        var data = localStorage.getItem('cart'); 

        if (data == undefined || data == null || data == "")
        {
        }else{
            let sum = 0;
            setCart(JSON.parse(localStorage.getItem('cart')))
            setCartLength(JSON.parse(localStorage.getItem('cart'))?.length)
            JSON.parse(localStorage.getItem('cart')).forEach((el) =>  {sum += el.cartAmount})
            setTotal(sum)
            calculateAllOnLoad()
            
        }
       
        new Promise(() => setTimeout(setLoading, 3000));

     
    }, [cart.quantity])

    const addQuantity = (id) =>{
        const updatedCart = cart?.map((post) =>{
            if(post?.productId === id ){
                 const initialCart = JSON.parse(localStorage.getItem('cart'))
                 for(let initial = 0; initial < initialCart.length; initial++){
                     console.log(initialCart[initial].productId)
                     if(initialCart[initial].productId === post?.productId){
                         if(initialCart[initial].actualProductQuantity > initialCart[initial].quantity){
                            post.quantity++
                            post.cartAmount = post.quantity * initialCart[initial].initialAmount
                            setTotal(total +  initialCart[initial].initialAmount)
                         }        
                     }
                 }
                //  post.cartAmount = post.cartAmount * post.quantity
                //  setTotal(total +  post.cartAmount)

            }
            return post
        });
      
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
      
  
    }

    const subtractQuantity = (id) =>{
        const updatedCart = cart?.map((post) =>{
            if(post?.productId === id && post?.quantity > 1){
                 post.quantity--
                 const initialCart = JSON.parse(localStorage.getItem('cart'))
                 for(let initial = 0; initial < initialCart.length; initial++){
                     console.log(initialCart[initial].productId)
                     if(initialCart[initial].productId === post?.productId){
                        post.cartAmount = post.quantity * initialCart[initial].initialAmount
                        console.log(post.quantity, initialCart[initial].initialAmount)
                        setTotal(total -  initialCart[initial].initialAmount)

                     }
                 }
                 
            }
            return post
        });
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    }

    const deleteCartItem = (id) => {
        console.log("delete::", id)
        for(let i =0; i < cart.length; i++){
            if(cart[i].productId === id){
            
                cart.splice(i, 1)
                let sum = 0;

                localStorage.setItem('cart', JSON.stringify(cart)); 
                setCartLength(JSON.parse(localStorage.getItem('cart'))?.length ?? 0)
                setCart(cart)
                JSON.parse(localStorage.getItem('cart')).forEach((el) =>  {sum += el.cartAmount})
                setTotal(sum)
                cart.total = sum

            }

        }
    }

    const calculateAllOnLoad = () => {
        const initialCart = JSON.parse(localStorage.getItem('cart'))

        const updatedCart = initialCart?.map((post) =>{
          
                 for(let initial = 0; initial < initialCart.length; initial++){
                    initialCart[initial].cartAmount = initialCart[initial].quantity * initialCart[initial].initialAmount
                        //setTotal(total +  initialCart[initial].initialAmount)                     
                 }
               
            return post
        });
        let sum = 0;
        setCart(updatedCart)
        updatedCart.forEach((el) =>  {sum += el.cartAmount})
        setTotal(sum)  
    }



    
  return (
    <div>
         { loading ? <Loader /> : ""}
   
        <NavBar cartLength={cartLength} />

        {/* This section is for the money protection message */}
        <div className='secured-payment'>
            We protect your money until item is delivered safely
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.7714 12.3856C13.4952 12.3856 13.2714 12.1618 13.2714 11.8856L13.2714 7.43587L6.58243 14.1248C6.38717 14.32 6.07059 14.3201 5.87533 14.1248C5.68006 13.9295 5.68006 13.6129 5.87533 13.4177L12.5642 6.72876L8.1145 6.72876C7.83835 6.72876 7.6145 6.5049 7.6145 6.22876C7.6145 5.95262 7.83835 5.72876 8.1145 5.72876L13.7714 5.72876C14.0475 5.72876 14.2714 5.95262 14.2714 6.22876L14.2714 11.8856C14.2714 12.1618 14.0475 12.3856 13.7714 12.3856Z" fill="#00A84A" stroke="#00A84A" strokeWidth="0.3"/>
            </svg>
        </div>

       
        <div className='cart-cards'>
            {/* This section is for stocked items */}
            {cart && cart.map((product) => {
            return ( <div key={product.productId} className='stocked-item'>
                 <div className='item-delete-can' onClick={() => deleteCartItem(product.productId)}>
                    <img className="image-delete-can" src={"/images/delete.png"} alt="delete-can"/>
                </div>
             <div className='stocked-item-details'>
                 <div className='item-image-box'>
                     <img className="item-image" src={product.cartImage} alt="item-image"/>
                 </div>
                 <div className='item-description-container'>
                     <ul className='item-description'>
                         <li className='item-name'>{product.name}</li>
                         <li className='item-size'>Size: {product.size}</li>
                         <li className='item-color'>Color: {product.color ?? "Black"}</li>
                     </ul>
                 </div>
                
             </div>
            
             {/* This section is for increasing and decreasing quantity of items */}
             <div className='cart-ctas'>
                 <div className='price-container'>
                     GHS {product.cartAmount}
                 </div>
                 <div className='quantity-container'>
                     <div className='reduce-quantity' onClick={() => subtractQuantity(product.productId)}>
                        <img className="add-q"  src={"/favicon/subtract-icon.svg"} alt="item-image"/>
                     </div>
                     
                     <div className='quantity-number'>
                         {product.quantity}
                     </div>
                     <div className='add-quantity' onClick={() => addQuantity(product.productId)} >
                        <img className="reduce-q" src={"/favicon/add-icon.svg"} alt="item-image"/>
                     </div>
                 </div>
             </div>
         </div>
           ) })}
           

             {/* This section is for one of ones */}
            {/* <div className='one-of-ones'>
                <div className='stocked-item-details'>
                    <div className='item-image-box'>
                        <img className="item-image" src={"/images/p3.jpeg"} alt="item-image"/>
                    </div>
                    <div className='item-description-container'>
                        <ul className='item-description'>
                            <li className='item-name'>Strap Bag</li>
                            <li className='item-size'>Size: M</li>
                            <li className='item-color'>Color: Black</li>
                        </ul>
                    </div>
                </div>
                <div className='cart-ctas'>
                    <div className='price-container'>
                        GHS 80.00
                    </div>
                </div>
            </div> */}
          
        </div>

        {/* This section is the checkout card*/}
        <div className='sticky-checkout'>
            <div className='sticky-checkout-details'>
                <div>Total</div>
                <div>GHS {total.toFixed(2)}</div>
            </div>
            <Link to={'/checkout'}>
                <input className="checkout-cta" type="submit" value="Checkout "/>
            </Link>
        </div>
    

    </div>
    
  )
}

export default Cart